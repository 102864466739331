'use client'
import { useCallback, useEffect } from "react";
import styles from './BackToTopButton.module.scss';

const BackToTopButton = () => {
  const scrollToHead = useCallback(() => {
    const header = document.querySelector('[data-fixed-header]') as HTMLElement;
    if (header) {
      header.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const header = document.querySelector('[data-fixed-header]') as HTMLElement;
    if (header) {
      header.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <div onClick={scrollToHead} className={styles.footer__toTop}>Page Top</div>
  )
}

export default BackToTopButton;
